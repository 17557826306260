import { AbstractConnector } from '@web3-react/abstract-connector'
import INJECTED_ICON_URL from '../assets/images/arrow-right.svg'
import COINBASE_ICON_URL from '../assets/images/coinbaseWalletIcon.svg'
import METAMASK_ICON_URL from '../assets/images/metamask.png'
import FORMATIC_ICON_URL from '../assets/images/fortmaticIcon.png'
import PORTIS_ICON_URL from '../assets/images/portisIcon.png'
import WALLET_CONNECT_ICON_URL from '../assets/images/walletConnectIcon.svg'
import { injected , CoinbaseWallet ,WalletConnect , fortmatic , portis} from '../connectors'
export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconURL: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
 
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconURL: METAMASK_ICON_URL,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  // COINBASE_INJECTED: {
  //   name: 'Open in Coinbase Wallet',
  //   iconURL: COINBASE_ICON_URL,
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true,
  // },
  COINBASE: {
    connector: CoinbaseWallet,
    name: 'Coinbase Wallet',
    iconURL: COINBASE_ICON_URL,
    description: 'Open in Coinbase Wallet app.',
    href: null,
    color: '#315CF5',
  },
  WALLET_CONNECT: {
    connector: WalletConnect,
    name: 'Wallet Connect',
    iconURL: WALLET_CONNECT_ICON_URL,
    description: 'Connect With Multiple Wallets',
    href: null,
    color: '#315CF5',
    mobile: true,
  },
 
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconURL: FORMATIC_ICON_URL,
    description: 'Connect With fortmatic.',
    href: null,
    color: '#315CF5',
  },
  PORTIS:{
    connector: portis,
    name: 'Portis',
    iconURL: PORTIS_ICON_URL,
    description: 'Connect With portis.',
    href: null,
    color: '#315CF5',
  },
}