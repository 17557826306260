export const SINGULAR_DAPP_BASE = "https://dapp.singulardex.com"

export const SINGULAR_DAPP_HOME = SINGULAR_DAPP_BASE;
export const SINGULAR_DAPP_PERPETUALS = `${SINGULAR_DAPP_BASE}/#/perpetuals`;
export const SINGULAR_DAPP_SLP = `${SINGULAR_DAPP_BASE}/#/slp`;
export const SINGULAR_DAPP_REFERRALS = `${SINGULAR_DAPP_BASE}/#/referrals`;
export const SINGULAR_DAPP_LAUNCHPAD = `${SINGULAR_DAPP_BASE}/#/launchpad`;

export const SINGULAR_APP_HOME = "/";
export const SINGULAR_APP_LIMIT_ORDER = "/#/limit-order";
export const SINGULAR_APP_STOP_LOSS = "/#/stop-loss";
