import React from 'react'
import { GelatoStopLimitOrderPanel, GelatoProvider } from '@gelatonetwork/stop-limit-orders-react'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import SwapContainer from '../../components/SwapContainer'

function Gelato({ children }: { children?: React.ReactNode }) {
  const { library, chainId, account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <GelatoProvider
      library={library}
      chainId={chainId}
      account={account ?? undefined}
      toggleWalletModal={toggleWalletModal}
      useDefaultTheme={false}
      handler={chainId==1?"uniswap_stoplimit":"quickswap_stoplimit"}
    >
      {children}
    </GelatoProvider>
  )
}

export default function StopLimitOrder() {
  return (
    <SwapContainer>
      <Gelato>
        <GelatoStopLimitOrderPanel />
      </Gelato>
    </SwapContainer>
  )
}
