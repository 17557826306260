import React from "react";
import {
  GelatoLimitOrderPanel,
  GelatoProvider,
} from "@gelatonetwork/limit-orders-react";
import { useActiveWeb3React } from "../../hooks/web3";
import { useWalletModalToggle } from "../../state/application/hooks";
import SwapContainer from "../../components/SwapContainer";

function Gelato({ children }: { children?: React.ReactNode }) {
  const { library, chainId, account } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle();

  // Set your own bases if you want specific tokens as bases, otherwise leave undefined for default ones (see constants/routing)

  return (
    <GelatoProvider
      library={library}
      chainId={chainId}
      account={account ?? undefined}
      toggleWalletModal={toggleWalletModal}
      useDefaultTheme={false}
      handler={"uniswap"}
    >
      {children}
    </GelatoProvider>
  );
}

export default function LimitOrder() {
  return (
    <SwapContainer>
      <Gelato>
        <GelatoLimitOrderPanel />
      </Gelato>
    </SwapContainer>
  );
}
