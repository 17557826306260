import { PropsWithChildren } from "react";
import styled from "styled-components/macro";
import { darken } from "polished";
import { NavLink } from "react-router-dom";
import Row from "../Row";

interface SwapContainerProps {
  className?: string;
}

const activeClassName = "ACTIVE";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const HeaderLinks = styled(Row)`
  background-color: ${({ theme }) => theme.bg0};
  border-radius: 30px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-self: center;
  margin: 0;
  overflow: auto;
  padding: 12px;
  width: fit-content;
  height: 60px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
  `};
`;

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  outline: none;
  padding: 4px 12px;
  text-decoration: none;
  white-space: nowrap;
  width: fit-content;
  height: 36px;

  &.${activeClassName} {
    border-radius: 18px;
    border: 2px solid #55c3e7!important;
    color: ${({ theme }) => theme.text1};
    font-weight: 600;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`;


const SwapContainer = ({ children, className }: PropsWithChildren<SwapContainerProps>) => {
  return (
    <Container>
      <HeaderLinks>
        <StyledNavLink id={`limit-order-nav-link`} to={"/limit-order"}>
          Limit Orders
        </StyledNavLink>
        <StyledNavLink id={`stop-limit-nav-link`} to={"/stop-limit-order"}>
          Stop Loss
        </StyledNavLink>
        {/* <StyledNavLink id={`pools-nav-link`} to={'/range-order'}>
          Range Orders
        </StyledNavLink> */}
      </HeaderLinks>
      {children}
    </Container>
  )
}

export default SwapContainer;
