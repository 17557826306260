"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GELATO_EJECT_LP_ADDRESS = exports.GELATO_RANGE_ORDERS_ADDRESS = exports.SUBGRAPH_URL = exports.NETWORK_HANDLERS = exports.NETWORK_NAME = exports.CHAIN_ID = exports.ETH_ADDRESS = void 0;
exports.ETH_ADDRESS = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
exports.CHAIN_ID = {
    MAINNET: 1,
    OPTIMISM: 10,
    ARBITRUM: 42161,
    MATIC: 137,
    GOERLI: 5,
};
exports.NETWORK_NAME = {
    [exports.CHAIN_ID.MAINNET]: "Ethereum",
    [exports.CHAIN_ID.OPTIMISM]: "Optimism",
    [exports.CHAIN_ID.ARBITRUM]: "Arbitrum",
    [exports.CHAIN_ID.MATIC]: "Polygon (matic)",
    [exports.CHAIN_ID.GOERLI]: "Goerli",
};
exports.NETWORK_HANDLERS = {
    [exports.CHAIN_ID.MAINNET]: ["uniswap"],
    [exports.CHAIN_ID.MATIC]: ["quickswap", "polydex", "cafeswap", "quickswap_stoploss"],
};
exports.SUBGRAPH_URL = {
    [exports.CHAIN_ID.MAINNET]: "https://api.thegraph.com/subgraphs/name/gelatodigital/range-orders-mainnet",
    [exports.CHAIN_ID.OPTIMISM]: "https://api.thegraph.com/subgraphs/name/gelatodigital/range-orders-optimism",
    [exports.CHAIN_ID.ARBITRUM]: "https://api.thegraph.com/subgraphs/name/gelatodigital/range-orders-arbitrum",
    [exports.CHAIN_ID.MATIC]: "https://api.thegraph.com/subgraphs/name/gelatodigital/range-orders-polygon",
    [exports.CHAIN_ID.GOERLI]: "https://api.thegraph.com/subgraphs/name/gelatodigital/range-orders-goerli",
};
exports.GELATO_RANGE_ORDERS_ADDRESS = {
    [exports.CHAIN_ID.MAINNET]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.OPTIMISM]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.ARBITRUM]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.MATIC]: "0xB8c1433cd9dF6F07f82E9a79bC8352c1d582f17E",
    [exports.CHAIN_ID.GOERLI]: "0x0000000000000000000000000000000000000000",
};
exports.GELATO_EJECT_LP_ADDRESS = {
    [exports.CHAIN_ID.MAINNET]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.OPTIMISM]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.ARBITRUM]: "0x0000000000000000000000000000000000000000",
    [exports.CHAIN_ID.MATIC]: "0x825832a5A589ed9500CaEE2aa2D2c3117218D6A9",
    [exports.CHAIN_ID.GOERLI]: "0x0000000000000000000000000000000000000000",
};
