import axios from "axios";
import React ,{useState,useEffect} from "react";
import Marquee from "react-fast-marquee";
import api from './api/api.json'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendDown,faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'

const IframeGraph = (props) => {
const iframe = `<iframe id="iframeId" frameBorder="0" src="${props.src}" style="width:96vw;height:100vh;box-shadow: inset 0px 11px 8px -10px black, inset 0px -11px 8px -10px black;"></iframe>`
// setTimeout(()=>{
// var iframe1 = document.getElementById("iframeId");
// iframe1.contentWindow.myFunction(
//     `setInterval(()=>{
//         for(var key in document.getElementsByClassName("custom-9qd6ch")){
//             document.getElementsByClassName("custom-9qd6ch")[key].style.display="none"
//         }
//     },50)`
// );
// })

return(
    <div>
    <div  dangerouslySetInnerHTML={{__html: iframe}} />
  </div>
  )};

export default IframeGraph;