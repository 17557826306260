"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getECR20 = exports.getUniswapV3Pool = exports.getRangeOrder = exports.getEjectLP = void 0;
const types_1 = require("./types");
const constants_1 = require("../constants");
const IUniswapV3Pool_json_1 = require("@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json");
const contracts_1 = require("@ethersproject/contracts");
const getEjectLP = (chainId, signerOrProvider) => {
    return types_1.EjectLP__factory.connect(constants_1.GELATO_EJECT_LP_ADDRESS[chainId], signerOrProvider);
};
exports.getEjectLP = getEjectLP;
const getRangeOrder = (chainId, signerOrProvider) => {
    return signerOrProvider
        ? types_1.RangeOrder__factory.connect(constants_1.GELATO_RANGE_ORDERS_ADDRESS[chainId], signerOrProvider)
        : new contracts_1.Contract(constants_1.GELATO_RANGE_ORDERS_ADDRESS[chainId], types_1.RangeOrder__factory.createInterface());
};
exports.getRangeOrder = getRangeOrder;
const getUniswapV3Pool = (pool, signerOrProvider) => {
    return new contracts_1.Contract(pool, IUniswapV3Pool_json_1.abi, signerOrProvider);
};
exports.getUniswapV3Pool = getUniswapV3Pool;
const getECR20 = (erc20, signerOrProvider) => {
    return signerOrProvider
        ? types_1.ERC20__factory.connect(erc20, signerOrProvider)
        : new contracts_1.Contract(erc20, types_1.ERC20__factory.createInterface());
};
exports.getECR20 = getECR20;
