import { ChainId } from '@dcl/schemas/dist/dapps/chain-id'
import { ProviderType } from '@dcl/schemas/dist/dapps/provider-type'

const configuration = Object.freeze({
  storageKey: 'decentraland-connect-storage-key',

  [ProviderType.INJECTED]: {},

  [ProviderType.FORTMATIC]: {
    apiKeys: {
      [ChainId.ETHEREUM_MAINNET]: 'pk_live_F8E24DF8DD5BCBC5',
    },
    urls: getRpcUrls(ProviderType.FORTMATIC)
  },

  [ProviderType.NETWORK]: {
    urls: getRpcUrls(ProviderType.NETWORK)
  },

  [ProviderType.WALLET_LINK]: {
    appName: 'tradix',
    urls: getRpcUrls(ProviderType.WALLET_LINK)
  },

  [ProviderType.WALLET_CONNECT_V2]: {
    projectId: 'cc312cac084c4b4d6560046da7e6df23',
    urls: getRpcUrls(ProviderType.WALLET_CONNECT_V2),
    chains: {
      [ChainId.ETHEREUM_MAINNET]: {
        chains: [ChainId.ETHEREUM_MAINNET],
        optionalChains: [56,137]
      },
    }
  }
})

export function getConfiguration() {
  return configuration
}

export function getRpcUrls(providerType: ProviderType) {
  const rpcUrls = {
    [ChainId.ETHEREUM_MAINNET]: 'https://rpc.decentraland.org/mainnet',
    [ChainId.POLYGON]: 'https://rpc.decentraland.org/polygon',
    [56]: 'https://rpc.ankr.com/bsc'

  }

  let project = ''

  switch (providerType) {
    case ProviderType.WALLET_LINK:
      project = 'walletlink'
      break
    case ProviderType.FORTMATIC:
      project = 'fortmatic'
      break
    case ProviderType.WALLET_CONNECT_V2:
      project = 'walletconnect-v2'
      break
    default:
      break
  }

  if (project) {
    for (const chainId in rpcUrls) {
      rpcUrls[chainId] += `?project=${project}`
    }
  }

  return rpcUrls
}
