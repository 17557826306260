import { Web3Provider } from '@ethersproject/providers';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { InjectedConnector } from '@web3-react/injected-connector';
import getLibrary from '../utils/getLibrary';
import { NetworkConnector } from './NetworkConnector';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import {FortmaticConnector } from '@web3-react/fortmatic-connector'
import {PortisConnector } from '@web3-react/portis-connector'
import {WalletConnectV2Connector} from './WalletConnectV2Connector'
const INFURA_KEY = "69debe8108314210b767a76b0396be0e"; //process.env.REACT_APP_INFURA_KEY
const DEFAULT_NETWORK_ID = Number(
  process.env.REACT_APP_DEFAULT_NETWORK_ID || 1
);


if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

const NETWORK_URLS: {
  [chainId: number]: string
} = {
  [1]: `https://mainnet.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  [4]: `https://rinkeby.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  [3]: `https://ropsten.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  [5]: `https://goerli.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  [25]: 'https://evm.cronos.org',
  [42]: `https://kovan.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  [56]: 'https://bsc-dataseed.binance.org',
  [137]: 'https://rpc-mainnet.maticvigil.com',
  [43114]: 'https://api.avax.network/ext/bc/C/rpc'
}
 
const SUPPORTED_CHAIN_IDS =   [1, 3, 5,25, 56, 137, 43114]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: DEFAULT_NETWORK_ID,
})


let networkLibrary: Web3Provider | undefined


export const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/69debe8108314210b767a76b0396be0e`,
  appName: "Tradix Web",
  supportedChainIds:SUPPORTED_CHAIN_IDS,
 });
 export const WalletConnect = new WalletConnectV2Connector(
  1
 );

// export const injected = new InjectedConnector({
//   supportedChainIds: SUPPORTED_CHAIN_IDS
// })
export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
 });
export const fortmatic = new FortmaticConnector({
  apiKey: "pk_live_9EC0EB121910FEAF", // formatic live api key;
  chainId: 1
})

export const portis = new PortisConnector({
  dAppId:   "4a33e923-0ca7-40bc-aa41-a35e61f1d892" , //process.env.REACT_APP_PORTIS_ID,
  networks: [1]
})


export const gnosisSafe = new SafeAppConnector()