"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeOrder__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: "contract INonfungiblePositionManager",
                name: "nftPositionManager_",
                type: "address",
            },
            {
                internalType: "contract IEjectLP",
                name: "eject_",
                type: "address",
            },
            {
                internalType: "contract IWETH9",
                name: "WETH9_",
                type: "address",
            },
            {
                internalType: "address",
                name: "rangeOrderResolver_",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount0",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount1",
                type: "uint256",
            },
        ],
        name: "LogCancelRangeOrder",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "pool",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amountIn",
                type: "uint256",
            },
        ],
        name: "LogSetRangeOrder",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "Paused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "Unpaused",
        type: "event",
    },
    {
        inputs: [],
        name: "WETH9",
        outputs: [
            {
                internalType: "contract IWETH9",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId_",
                type: "uint256",
            },
            {
                components: [
                    {
                        internalType: "contract IUniswapV3Pool",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "zeroForOne",
                        type: "bool",
                    },
                    {
                        internalType: "int24",
                        name: "tickThreshold",
                        type: "int24",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minLiquidity",
                        type: "uint256",
                    },
                    {
                        internalType: "address payable",
                        name: "receiver",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "maxFeeAmount",
                        type: "uint256",
                    },
                ],
                internalType: "struct RangeOrderParams",
                name: "params_",
                type: "tuple",
            },
            {
                internalType: "uint256",
                name: "startTime_",
                type: "uint256",
            },
        ],
        name: "cancelRangeOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "eject",
        outputs: [
            {
                internalType: "contract IEjectLP",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "nftPositionManager",
        outputs: [
            {
                internalType: "contract INonfungiblePositionManager",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        name: "onERC721Received",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "paused",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "rangeOrderResolver",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "contract IUniswapV3Pool",
                        name: "pool",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "zeroForOne",
                        type: "bool",
                    },
                    {
                        internalType: "int24",
                        name: "tickThreshold",
                        type: "int24",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minLiquidity",
                        type: "uint256",
                    },
                    {
                        internalType: "address payable",
                        name: "receiver",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "maxFeeAmount",
                        type: "uint256",
                    },
                ],
                internalType: "struct RangeOrderParams",
                name: "params_",
                type: "tuple",
            },
        ],
        name: "setRangeOrder",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class RangeOrder__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.RangeOrder__factory = RangeOrder__factory;
RangeOrder__factory.abi = _abi;
