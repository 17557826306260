"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNetworkGasToken = exports.isEthereumChain = exports.isPolygonChainId = exports.isRangeOrderSupportedChain = void 0;
const constants_1 = require("../constants");
const isRangeOrderSupportedChain = (chainId) => {
    switch (chainId) {
        case 137:
            return true;
        default:
            return false;
    }
};
exports.isRangeOrderSupportedChain = isRangeOrderSupportedChain;
const isPolygonChainId = (chainId) => {
    if (chainId === 137) {
        return true;
    }
    return false;
};
exports.isPolygonChainId = isPolygonChainId;
const isEthereumChain = (chainId) => {
    switch (chainId) {
        case 1:
            return true;
        case 3:
            return true;
        case 5:
            return true;
        default:
            return false;
    }
};
exports.isEthereumChain = isEthereumChain;
const isNetworkGasToken = (token) => {
    if (token.toLowerCase() === constants_1.ETH_ADDRESS.toLowerCase()) {
        return true;
    }
    else {
        return false;
    }
};
exports.isNetworkGasToken = isNetworkGasToken;
