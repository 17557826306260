import { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Header from "./components/Header";
import Polling from "./components/Header/Polling";
import Web3ReactManager from "./components/Web3ReactManager";
import LimitOrder from "./pages/LimitOrder";
import StopLimitOrder from "./pages/StopLimitOrder";
import RangeOrder from "./pages/RangeOrder";
import "./App.css";
import IframeGraph from "./IframeGraph";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { bsc, mainnet, polygon } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const BodyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 120px;
  width: 100%;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 120px 16px 16px 16px;
  `};
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  position: fixed;
  margin-top: 0px;
  z-index: 2;
`;

function App() {
  const chains = [bsc, mainnet, polygon];

  const projectId = "cc312cac084c4b4d6560046da7e6df23";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
  const location = useLocation();
  const [src, setSrc] = useState("");
  const [iframeLoading, setIframeLoading] = useState(false);
  useEffect(() => {
    if (location.pathname !== "/") {
      setIframeLoading(false);
    }
  }, [location]);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>

          <BodyWrapper>
            {iframeLoading && <IframeGraph src={src} />}
            <Polling />
            {!iframeLoading && (
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path="/" component={LimitOrder} />
                  <Route
                    exact
                    strict
                    path="/limit-order"
                    component={LimitOrder}
                  />
                  <Route
                    exact
                    strict
                    path="/stop-limit-order"
                    component={StopLimitOrder}
                  />
                  <Route
                    exact
                    strict
                    path="/range-order"
                    component={RangeOrder}
                  />
                  {/* <Route component={LimitOrder} /> */}
                </Switch>
              </Web3ReactManager>
            )}
          </BodyWrapper>
        </AppWrapper>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </WagmiConfig>
    </>
  );
}

export default App;
