"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EXPIRED_RANGE_ORDER_BY_RECEIVER = exports.GET_CANCELLED_RANGE_ORDER_BY_RECEIVER = exports.GET_EXECUTED_RANGE_ORDER_BY_RECEIVER = exports.GET_OPEN_RANGE_ORDER_BY_RECEIVER = exports.GET_RANGE_ORDER_BY_TOKEN_ID = void 0;
const graphql_request_1 = require("graphql-request");
exports.GET_RANGE_ORDER_BY_TOKEN_ID = (0, graphql_request_1.gql) `
  query getRangeOrderByTokenId($tokenId: ID) {
    rangeOrders(where: { id: $tokenId }) {
      id
      status
      creator
      tickThreshold
      zeroForOne
      startTime
      expiryTime
      amountIn
      receiver
      feeToken
      resolver
      maxFeeAmount
      feeAmount
      amount0
      amount1
      pool
      submittedTxHash
      executedTxHash
      cancelledTxHash
      createdAt
      updatedAt
      createdAtBlock
      updatedAtBlock
      updatedAtBlockHash
    }
  }
`;
exports.GET_OPEN_RANGE_ORDER_BY_RECEIVER = (0, graphql_request_1.gql) `
  query getOpenRangeOrderByUser($receiver: ID) {
    rangeOrders(where: { receiver: $receiver, status: submitted }) {
      id
      status
      creator
      tickThreshold
      zeroForOne
      startTime
      expiryTime
      amountIn
      receiver
      feeToken
      resolver
      maxFeeAmount
      feeAmount
      amount0
      amount1
      pool
      submittedTxHash
      executedTxHash
      cancelledTxHash
      createdAt
      updatedAt
      createdAtBlock
      updatedAtBlock
      updatedAtBlockHash
    }
  }
`;
exports.GET_EXECUTED_RANGE_ORDER_BY_RECEIVER = (0, graphql_request_1.gql) `
  query getExecutedRangeOrderByUser($receiver: ID) {
    rangeOrders(where: { receiver: $receiver, status: executed }) {
      id
      status
      creator
      tickThreshold
      zeroForOne
      startTime
      expiryTime
      amountIn
      receiver
      feeToken
      resolver
      maxFeeAmount
      feeAmount
      amount0
      amount1
      pool
      submittedTxHash
      executedTxHash
      cancelledTxHash
      createdAt
      updatedAt
      createdAtBlock
      updatedAtBlock
      updatedAtBlockHash
    }
  }
`;
exports.GET_CANCELLED_RANGE_ORDER_BY_RECEIVER = (0, graphql_request_1.gql) `
  query getCancelledRangeOrderByUser($receiver: ID) {
    rangeOrders(where: { receiver: $receiver, status: cancelled }) {
      id
      status
      creator
      tickThreshold
      zeroForOne
      startTime
      expiryTime
      amountIn
      receiver
      feeToken
      resolver
      maxFeeAmount
      feeAmount
      amount0
      amount1
      pool
      submittedTxHash
      executedTxHash
      cancelledTxHash
      createdAt
      updatedAt
      createdAtBlock
      updatedAtBlock
      updatedAtBlockHash
    }
  }
`;
exports.GET_EXPIRED_RANGE_ORDER_BY_RECEIVER = (0, graphql_request_1.gql) `
  query getCancelledRangeOrderByUser($receiver: ID) {
    rangeOrders(where: { receiver: $receiver, status: expired }) {
      id
      status
      creator
      tickThreshold
      zeroForOne
      startTime
      expiryTime
      amountIn
      receiver
      feeToken
      resolver
      maxFeeAmount
      feeAmount
      amount0
      amount1
      pool
      submittedTxHash
      executedTxHash
      cancelledTxHash
      createdAt
      updatedAt
      createdAtBlock
      updatedAtBlock
      updatedAtBlockHash
    }
  }
`;
