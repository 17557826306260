"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryExpiredRangeOrderByUser = exports.queryCancelledRangeOrderByUser = exports.queryExecutedRangeOrderByUser = exports.queryOpenRangeOrderByUser = exports.queryRangeOrder = void 0;
const tslib_1 = require("tslib");
const graphql_request_1 = require("graphql-request");
const constants_1 = require("../../constants");
const constants_2 = require("./constants");
const queryRangeOrder = (chainId, tokenId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = constants_1.SUBGRAPH_URL[chainId]
            ? yield (0, graphql_request_1.request)(constants_1.SUBGRAPH_URL[chainId], constants_2.GET_RANGE_ORDER_BY_TOKEN_ID, {
                tokenId,
            })
            : { orders: [] };
        return result === null || result === void 0 ? void 0 : result.rangeOrders[0];
    }
    catch (error) {
        throw new Error("Could not query subgraph for Range Order");
    }
});
exports.queryRangeOrder = queryRangeOrder;
const queryOpenRangeOrderByUser = (chainId, receiver) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = constants_1.SUBGRAPH_URL[chainId]
            ? yield (0, graphql_request_1.request)(constants_1.SUBGRAPH_URL[chainId], constants_2.GET_OPEN_RANGE_ORDER_BY_RECEIVER, {
                receiver,
            })
            : { orders: [] };
        return result === null || result === void 0 ? void 0 : result.rangeOrders;
    }
    catch (error) {
        throw new Error("Could not query subgraph for Range Order");
    }
});
exports.queryOpenRangeOrderByUser = queryOpenRangeOrderByUser;
const queryExecutedRangeOrderByUser = (chainId, receiver) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = constants_1.SUBGRAPH_URL[chainId]
            ? yield (0, graphql_request_1.request)(constants_1.SUBGRAPH_URL[chainId], constants_2.GET_EXECUTED_RANGE_ORDER_BY_RECEIVER, {
                receiver,
            })
            : { orders: [] };
        return result === null || result === void 0 ? void 0 : result.rangeOrders;
    }
    catch (error) {
        throw new Error("Could not query subgraph for Range Order");
    }
});
exports.queryExecutedRangeOrderByUser = queryExecutedRangeOrderByUser;
const queryCancelledRangeOrderByUser = (chainId, receiver) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = constants_1.SUBGRAPH_URL[chainId]
            ? yield (0, graphql_request_1.request)(constants_1.SUBGRAPH_URL[chainId], constants_2.GET_CANCELLED_RANGE_ORDER_BY_RECEIVER, {
                receiver,
            })
            : { orders: [] };
        return result === null || result === void 0 ? void 0 : result.rangeOrders;
    }
    catch (error) {
        throw new Error("Could not query subgraph for Range Order");
    }
});
exports.queryCancelledRangeOrderByUser = queryCancelledRangeOrderByUser;
const queryExpiredRangeOrderByUser = (chainId, receiver) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = constants_1.SUBGRAPH_URL[chainId]
            ? yield (0, graphql_request_1.request)(constants_1.SUBGRAPH_URL[chainId], constants_2.GET_EXPIRED_RANGE_ORDER_BY_RECEIVER, {
                receiver,
            })
            : { orders: [] };
        return result === null || result === void 0 ? void 0 : result.rangeOrders;
    }
    catch (error) {
        throw new Error("Could not query subgraph for Range Order");
    }
});
exports.queryExpiredRangeOrderByUser = queryExpiredRangeOrderByUser;
